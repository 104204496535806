import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import Home from 'ui/pages/home';

// components
import history from './history';

export default function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/' component={Home} />
      </Switch>
    </Router>
  );
}
