import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import LottieAnimation from 'ui/components/lottie';
import Fade from 'react-reveal/Fade';

import translateIcone from 'animation/translate-icone.json';
//import rising_bubbles from 'animation/rising_bubbles.json';
import backdrop_floating_bubbles from 'animation/backdrop_floating_bubbles.json';
import styles from './why.module.scss';

// contexts
import { LanguageContext } from 'context';

// helpers
import { translations } from 'resources';

export default function Why() {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  return (
    <section id='section_why' className={styles.section_why_container}>
      <Fade bottom>
        <div className={styles.why_background}>
          <LottieAnimation loop lotti={backdrop_floating_bubbles} height={'100%'} width={'100%'} />
        </div>
        <div className={styles.section_why_image_container}>
          <LottieAnimation loop={false} lotti={translateIcone} height={'100%'} width={'100%'} />
        </div>
        <div className={styles.section_why_content_container}>
          <h2 className={styles.why_title}>{translations[appLanguage]['g12']}</h2>
          <p className={styles.why_motto_text}>{translations[appLanguage]['g33']}</p>
          <p className={styles.why_text}>
            <span className={styles.why_text_icon}>*</span>
            {translations[appLanguage]['g13']}
          </p>
          <Link to='/' className={styles.why_btn}>
          {translations[appLanguage]['g36']}
          </Link>
        </div>
      </Fade>
    </section>
  );
}
