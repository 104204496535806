import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Col, Row } from 'antd';

import { TwitterOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';

import styles from './footer.module.scss';

// contexts
import { LanguageContext } from 'context';

// helpers
import { translations } from 'resources';

export default function Footer() {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  return (
    <footer className={styles.footer}>
      <Row className={styles.footerContainer}>
        <Row className={styles.subContainer}>
          <div className={styles.subContent}>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.company}>
              © 2021
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.policies}>
              <div className={styles.circle} />
              <Link to='/' target='_blank' className={styles.policy}>
              {translations[appLanguage]['g34']}
              </Link>
              <div className={styles.circle} />
              <Link to='/' target='_blank' className={styles.policy}>
              {translations[appLanguage]['g35']}
              </Link>
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} className={styles.socialMedia}>
              <a href='https://twitter.com/' target='_blank' rel='noopener noreferrer'>
                <TwitterOutlined className={styles.social} />
              </a>
              <a href='https://www.facebook.com/' target='_blank' rel='noopener noreferrer'>
                <FacebookOutlined className={styles.social} />
              </a>
              <a href='https://www.instagram.com/' target='_blank' rel='noopener noreferrer'>
                <InstagramOutlined className={styles.social} />
              </a>
            </Col>
          </div>
        </Row>
      </Row>
    </footer>
  );
}
