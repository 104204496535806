import React from 'react';
import Lottie from 'react-lottie';

export default function LottieAnimation({ lotti, width, height, loop }) {
  const defaultOptions = {
    loop: loop,
    autoplay: true,
    animationData: lotti,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} height={height} width={width} />;
}
