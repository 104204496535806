import { CHANGE_APP_LANGUAGE } from 'context/language/keys';

export const initialState = {
  appLanguage: 'en',
  languageSetted: localStorage.getItem('languageSetted'),
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CHANGE_APP_LANGUAGE:
      return { ...state, appLanguage: payload, languageSetted: true };
    default:
      return state;
  }
};

export default reducer;
