import { CHANGE_APP_LANGUAGE } from 'context/language/keys';
import { supportedLanguages } from 'resources/strings';

export const changeAppLanguage = (dispatch) => (language) => {
  if (supportedLanguages.indexOf(language.toLowerCase()) >= 0) {
    dispatch({ type: CHANGE_APP_LANGUAGE, payload: language });
    localStorage.setItem('appLanguage', language);
    localStorage.setItem('languageSetted', true);
  }
};
