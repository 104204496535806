import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Col, Row } from 'antd';

/* Images */
import turkish_flag from '../images/turkish_flag2.jpg';
import english_flag from '../images/english_flag.png';
import arab_flag from '../images/arab_flag.png';
import iran_flag from '../images/iran_flag.png';

// contexts
import { LanguageContext } from 'context';

// helpers
import { translations } from 'resources';

import styles from './how.module.scss';

const How = () => {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  return (
    <section id='section_how' className={styles.section_how_container}>
      <Fade bottom>
        <article className={styles.section_how_details}>
          <h2 className={styles.section_how_title}>{translations[appLanguage]['g3']}</h2>
          {/* <p className={styles.section_how_info}>
            Ürünlerinizi tüm online pazarlarda en doğru yerde konumlandırarak satışlarınızı
            arttırın.
          </p> */}
        </article>
        <Row>
          <Col xs={24} md={6} lg={6} xl={6} className={styles.card_container}>
            <div className={`${styles.how_works_cards} ${styles.how_works_cards_1}`}>
              <div className={styles.how_works_card_img}>
                <img src={turkish_flag} alt='' />
              </div>
              <div className={styles.how_works_card_header}>
                <h3>{translations[appLanguage]['g4']}</h3>
              </div>
              <div className={styles.how_works_card_container}>
                <p>
                {translations[appLanguage]['g5']}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={6} lg={6} xl={6} className={styles.card_container}>
            <div className={`${styles.how_works_cards} ${styles.how_works_cards_2}`}>
              <div className={styles.how_works_card_img}>
                <img src={english_flag} alt='' />
              </div>
              <div className={styles.how_works_card_header}>
                <h3>{translations[appLanguage]['g6']}</h3>
              </div>
              <div className={styles.how_works_card_container}>
                <p>
                {translations[appLanguage]['g7']}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={6} lg={6} xl={6} className={styles.card_container}>
            <div className={`${styles.how_works_cards} ${styles.how_works_cards_3}`}>
              <div className={styles.how_works_card_img}>
                <img src={arab_flag} alt='' />
              </div>
              <div className={styles.how_works_card_header}>
                <h3>{translations[appLanguage]['g8']}</h3>
              </div>
              <div className={styles.how_works_card_container}>
                <p>
                {translations[appLanguage]['g9']}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={6} lg={6} xl={6} className={styles.card_container}>
            <div className={`${styles.how_works_cards} ${styles.how_works_cards_3}`}>
              <div className={styles.how_works_card_img}>
                <img src={iran_flag} alt='' />
              </div>
              <div className={styles.how_works_card_header}>
                <h3>{translations[appLanguage]['g10']}</h3>
              </div>
              <div className={styles.how_works_card_container}>
                <p>
                {translations[appLanguage]['g11']}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Fade>
    </section>
  );
};

export default How;
