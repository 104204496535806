import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { Select } from 'antd';

// contexts
import { LanguageContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './home-header.module.scss';
import logo from 'assets/images/logo.ico';

const { Option } = Select;

export default function HomeHeader() {
  const [menuIconVisible, setMenuIconVisible] = useState(true);

  const {
    state: { appLanguage },
    changeAppLanguage
  } = useContext(LanguageContext);

  const handleChange = (value) => {
    changeAppLanguage(value);
  };

  return (
    <>
      <div className={styles.home_header}>
        <div className={styles.logo_menu_icon_container}>
          <Link to='/' className={styles.logo_container}>
            <img className={styles.logo} src={logo} alt='logo' />
          </Link>
          <div
            onClick={() => setMenuIconVisible(!menuIconVisible)}
            className={styles.menu_icon_container}
          >
            {menuIconVisible ? (
              <MenuOutlined className={styles.menu_icon} />
            ) : (
              <CloseOutlined className={styles.menu_icon} />
            )}
          </div>
        </div>

        <div
          className={
            !menuIconVisible
              ? `${styles.menu_auth_container}`
              : `${styles.menu_auth_container} ${styles.hide_menu}`
          }
        >
          <div className={styles.menu_container}>
            <NavHashLink
              onClick={() => setMenuIconVisible(true)}
              smooth
              to='/#section_what'
              className={styles.home_header_menu_link}
            >
              {translations[appLanguage]['g29']}
            </NavHashLink>
            <NavHashLink
              onClick={() => setMenuIconVisible(true)}
              smooth
              to='/#section_how'
              className={styles.home_header_menu_link}
            >
              {translations[appLanguage]['g30']}
            </NavHashLink>
            <NavHashLink
              onClick={() => setMenuIconVisible(true)}
              smooth
              to='/#section_why'
              className={styles.home_header_menu_link}
            >
              {translations[appLanguage]['g31']}
            </NavHashLink>
            <NavHashLink
              onClick={() => setMenuIconVisible(true)}
              smooth
              to='/#section_packages'
              className={styles.home_header_menu_link}
            >
              {translations[appLanguage]['g32']}
            </NavHashLink>
          </div>

          <div className={styles.spacer} />

          <div className={styles.auth_btn_container}>
            <Select defaultValue={appLanguage} style={{ width: 120 }} onChange={handleChange}>
              <Option value='tr'>Türkçe</Option>
              <Option value='en'>English</Option>
              <Option value='ar'>عربي</Option>
            </Select>
          </div> 
        </div>
      </div>
    </>
  );
}
