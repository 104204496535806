import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Col, Row } from 'antd';

import styles from './packages.module.scss';

// contexts
import { LanguageContext } from 'context';

// helpers
import { translations } from 'resources';

const Packages = () => {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  return (
    <section id='section_packages' className={styles.section_packages_container}>
      <Fade bottom>
        <article className={styles.section_packages_details}>
          <h2 className={styles.section_packages_title}>{translations[appLanguage]['g14']}</h2>
          <p className={styles.section_packages_info}>
          {translations[appLanguage]['g15']}
          </p>
        </article>
        <Row className={styles.price_cards_container}>
          <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
            <div className={styles.price_cards}>
              <div className={styles.price_card_pricing}>
                <h3 style={{ border: '3px solid #ff7d00' }}>{translations[appLanguage]['g16']}</h3>
                <h1>{translations[appLanguage]['g19']}</h1>
                <p>{translations[appLanguage]['g18']}</p>
              </div>
              <div className={styles.divider} />
              <div className={styles.price_card_details}>
                <div className={styles.price_card_details_strong}>
                  <strong>{translations[appLanguage]['g17']}</strong>
                </div>
              </div>
              <div className={styles.btn_container}>
                <button className={styles.buy_btn}>{translations[appLanguage]['g37']}</button>
              </div>
            </div>
          </Col>
          <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
            <div className={styles.price_cards}>
              <div className={styles.price_card_pricing}>
                <h3 style={{ border: '3px solid #1890ff' }}>{translations[appLanguage]['g20']}</h3>
                <h1>{translations[appLanguage]['g23']}</h1>
                <p>{translations[appLanguage]['g22']}</p>
              </div>
              <div className={styles.divider} />
              <div className={styles.price_card_details}>
                <div className={styles.price_card_details_strong}>
                  <strong>{translations[appLanguage]['g21']}</strong>
                </div>
              </div>
              <div className={styles.btn_container}>
                <button className={styles.buy_btn}>{translations[appLanguage]['g37']}</button>
              </div>
            </div>
          </Col>
          <Col xs={24} md={8} lg={8} xl={8} className={styles.card_container}>
            <div className={styles.price_cards}>
              <div className={styles.price_card_pricing}>
                <h3 style={{ border: '3px solid #06ceb3' }}>{translations[appLanguage]['g24']}</h3>
                <h1>{translations[appLanguage]['g27']}</h1>
                <p>{translations[appLanguage]['g26']}</p>
              </div>
              <div className={styles.divider} />
              <div className={styles.price_card_details}>
                <div className={styles.price_card_details_strong}>
                  <strong>{translations[appLanguage]['g25']}</strong>
                </div>
              </div>
              <div className={styles.btn_container}>
                <button className={styles.buy_btn}>{translations[appLanguage]['g37']}</button>
              </div>
            </div>
          </Col>
        </Row>
      </Fade>
    </section>
  );
};

export default Packages;
