import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { WhatsAppOutlined } from '@ant-design/icons';

import LottieAnimation from 'ui/components/lottie';

// contexts
import { LanguageContext } from 'context';

// helpers
import { translations } from 'resources';

import languageLearning from 'animation/langauge-learning.json';
import styles from './what.module.scss';

export default function What() {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  return (
    <section id='section_what' className={styles.section_what_container}>
      <Fade bottom>
        <div className={styles.section_what_content_container}>
          <h2 className={styles.what_title}>{translations[appLanguage]['g1']}</h2>
          <p className={styles.what_text}>
          {translations[appLanguage]['g2']}
          </p>
          <p className={styles.what_text}>
          <WhatsAppOutlined /> <span style={{marginLeft: '8px'}}>{translations[appLanguage]['g28']}</span>
          </p>
          <Link to='/' className={styles.what_btn}>
          {translations[appLanguage]['g36']}
          </Link>
        </div>

        <div className={styles.section_what_image_container}>
          <LottieAnimation loop={false} lotti={languageLearning} height={'100%'} width={'100%'} />
        </div>
      </Fade>
    </section>
  );
}
