import React from 'react';

//components
import HomeHeader from './home-header';
import What from './what';
import Packages from './packages';
import How from './how';
import Why from './why';
import Footer from './footer';

//styles
import styles from './home.module.scss';

export default function Home() {
  return (
    <>
      <HomeHeader />
      <div className={styles.page_container}>
        <What />
        <How />
        <Why />
        <Packages />
        <Footer />
      </div>
    </>
  );
}
